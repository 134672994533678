import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <div className="inner">
          <ul className="icons">
            <li><a href="http://www.github.com/sawasawasawa" className="icon fa-github"></a></li>
            <li><a href="mailto:m.e.sawka@gmail.com" className="icon fa-envelope-o"></a></li>
            <li><a href="http://trzytygodnie.pl" className="icon fa-camera"></a></li>
            <li><a href="https://www.linkedin.com/in/mateusz-sawka-62b25055/" className="icon fa-linkedin"></a></li>
          </ul>
          <ul className="copyright">
            <li>&copy; sawka.pro</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Footer
