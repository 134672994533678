// import React from 'react'
// import Link from 'gatsby-link'
// import get from 'lodash/get'
// import Helmet from 'react-helmet'
//
// import thumb01 from '../assets/images/thumbs/music-map.png'
// import thumb02 from '../assets/images/thumbs/lyricspainter.png'
// import thumb03 from '../assets/images/thumbs/ranking.png'
// import thumb04 from '../assets/images/thumbs/word-portraits.png'
// import thumb05 from '../assets/images/thumbs/happiness.png'
// import thumb06 from '../assets/images/thumbs/generator.png'
// import Projects from "../components/Projects";
//
// const DEFAULT_IMAGES = [
//   {
//     href: 'http://138.68.90.224:3010/',
//     thumbnail: thumb01,
//     caption: 'Lublin Music Map',
//     description: 'Collection of sounds and places around Lublin'
//   },
//   {
//     href: 'http://lyricspainter.sawka.pro/',
//     thumbnail: thumb02,
//     caption: 'LyricsPainter',
//     description: 'Paint a wordcloud with lyrics of any artist'
//   },
//   {
//     href: 'http://www.sawka.pro/ranking',
//     thumbnail: thumb03,
//     caption: 'Polish Rappers\' Vocabulary',
//     description: 'Polish rappers ranked by their vocabulary'
//   },
//   {
//     href: 'http://www.sawka.pro/rapSlowa',
//     thumbnail: thumb04,
//     caption: 'Word Portraits',
//     description: 'Artists portayed with their own lyrics'
//   },
//   {
//     href: 'http://oszczesciu.wordpress.com',
//     thumbnail: thumb05,
//     caption: 'My master thesis on happiness (in an infographic)',
//     description: 'Analysis of the influence of social network on perceived happiness'
//   },
//   {
//     href: 'http://sawka.pro/generator',
//     thumbnail: thumb06,
//     caption: 'Speech generator (Polish)',
//     description: 'A fun tool to generate lengthy speeches'
//   }
// ];
//
// class HomeIndex extends React.Component {
//
//   constructor() {
//     super();
//
//     this.state = {
//       lightboxIsOpen: false,
//       currentImage: 0,
//     };
//
//     this.closeLightbox = this.closeLightbox.bind(this);
//     this.gotoNext = this.gotoNext.bind(this);
//     this.gotoPrevious = this.gotoPrevious.bind(this);
//     this.openLightbox = this.openLightbox.bind(this);
//     this.handleClickImage = this.handleClickImage.bind(this);
//   }
//
//   openLightbox(index, event) {
//     event.preventDefault();
//     this.setState({
//       currentImage: index,
//       lightboxIsOpen: true,
//     });
//   }
//
//   closeLightbox() {
//     this.setState({
//       currentImage: 0,
//       lightboxIsOpen: false,
//     });
//   }
//
//   gotoPrevious() {
//     this.setState({
//       currentImage: this.state.currentImage - 1,
//     });
//   }
//
//   gotoNext() {
//     this.setState({
//       currentImage: this.state.currentImage + 1,
//     });
//   }
//
//   handleClickImage() {
//     if (this.state.currentImage === this.props.images.length - 1) return;
//
//     this.gotoNext();
//   }
//
//   render() {
//     const siteTitle = this.props.data.site.siteMetadata.title
//     const siteDescription = this.props.data.site.siteMetadata.description
//
//     return (
//       <div>
//         <Helmet>
//           <title>{siteTitle}</title>
//           <meta name="description" content={siteDescription}/>
//         </Helmet>
//
//         <div id="main">
//
//           <section id="one">
//             <header className="major">
//               <h2>Hello, world!</h2>
//             </header>
//             <p>
//               I am a web developer with background in data analysis.
//             </p>
//             <p>
//               Combining my programming and analytical skills is what I enjoy most.
//               Having worked for a few years as a financial analyst I realized I needed more creativity at work, and
//               that turned me into programming.
//               Apart from programming I enjoy calisthenics, wandering around strange places and art.
//               <br/>
//
//             </p>
//             {/*<ul className="actions">*/}
//               {/*<li><a href="#" className="button">Learn More</a></li>*/}
//             {/*</ul>*/}
//           </section>
//
//           <section id="blog">
//             <h2>Writing</h2>
//
//             <ul >
//               <li><a href="/why_i_became_a_programmer">Why I became a programmer</a></li>
//               <li><a href="/how_i_became_a_programmer">...and how I did it :)</a></li>
//             </ul>
//           </section>
//
//           <section id="two">
//             <h2>Projects</h2>
//             {/*<p>Or <span className='cursive'>How I learned to program</span></p>*/}
//             <Projects images={DEFAULT_IMAGES.map(({href, thumbnail, caption, description}) => ({
//               href,
//               thumbnail,
//               caption,
//               description
//             }))}/>
//
//             {/*<ul className="actions">*/}
//               {/*<li><a href="#" className="button">Full Portfolio</a></li>*/}
//             {/*</ul>*/}
//           </section>
//
//           {/*<section id="three">*/}
//             {/*<h2>Get In Touch</h2>*/}
//             {/*<p>Accumsan pellentesque commodo blandit enim arcu non at amet id arcu magna. Accumsan orci faucibus id eu*/}
//               {/*lorem semper nunc nisi lorem vulputate lorem neque lorem ipsum dolor.</p>*/}
//             {/*<div className="row">*/}
//               {/*<div className="8u 12u$(small)">*/}
//                 {/*<form method="post" action="#">*/}
//                   {/*<div className="row uniform 50%">*/}
//                     {/*<div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name"/></div>*/}
//                     {/*<div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email"/>*/}
//                     {/*</div>*/}
//                     {/*<div className="12u"><textarea name="message" id="message" placeholder="Message"*/}
//                                                    {/*rows="4"></textarea></div>*/}
//                   {/*</div>*/}
//                 {/*</form>*/}
//                 {/*<ul className="actions">*/}
//                   {/*<li><input type="submit" value="Send Message"/></li>*/}
//                 {/*</ul>*/}
//               {/*</div>*/}
//               {/*<div className="4u 12u$(small)">*/}
//                 {/*<ul className="labeled-icons">*/}
//                   {/*<li>*/}
//                     {/*<h3 className="icon fa-home"><span className="label">Address</span></h3>*/}
//                     {/*1234 Somewhere Rd.<br/>*/}
//                     {/*Nashville, TN 00000<br/>*/}
//                     {/*United States*/}
//                   {/*</li>*/}
//                   {/*<li>*/}
//                     {/*<h3 className="icon fa-mobile"><span className="label">Phone</span></h3>*/}
//                     {/*000-000-0000*/}
//                   {/*</li>*/}
//                   {/*<li>*/}
//                     {/*<h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>*/}
//                     {/*<a href="#">hello@untitled.tld</a>*/}
//                   {/*</li>*/}
//                 {/*</ul>*/}
//               {/*</div>*/}
//             {/*</div>*/}
//           {/*</section>*/}
//
//         </div>
//
//       </div>
//     )
//   }
// }
//
// export default HomeIndex
//
// export const pageQuery = graphql`
//     query PageQuery {
//         site {
//             siteMetadata {
//                 title
//                 description
//             }
//         }
//     }
// `
import React from 'react'
import { Link, graphql } from 'gatsby'
import Projects from '../components/Projects'

import Layout from '../components/layout'
import SEO from '../components/seo'

import hot16 from '../assets/images/thumbs/hot16thumb.png'
import thumb01 from '../assets/images/thumbs/music-map.png'
import thumb02 from '../assets/images/thumbs/lyricspainter.png'
import thumb03 from '../assets/images/thumbs/ranking.png'
import thumb04 from '../assets/images/thumbs/word-portraits.png'
import thumb05 from '../assets/images/thumbs/happiness.png'
import thumb06 from '../assets/images/thumbs/generator.png'

const DEFAULT_IMAGES = [
  {
    href: '/hot16challenge2',
    thumbnail: hot16,
    caption: '#hot16challenge2 viz',
    description: 'Visualization of #hot16challenge2 songs',
  },
  {
    href: 'http://138.68.90.224:3010/',
    thumbnail: thumb01,
    caption: 'Lublin Music Map',
    description: 'Collection of sounds and places around Lublin',
  },
  {
    href: 'http://lyricspainter.sawka.pro/',
    thumbnail: thumb02,
    caption: 'LyricsPainter',
    description: 'Paint a wordcloud with lyrics of any artist',
  },
  {
    href: 'http://www.sawka.pro/ranking',
    thumbnail: thumb03,
    caption: "Polish Rappers' Vocabulary",
    description: 'Polish rappers ranked by their vocabulary',
  },
  {
    href: 'http://www.sawka.pro/rapSlowa',
    thumbnail: thumb04,
    caption: 'Word Portraits',
    description: 'Artists portayed with their own lyrics',
  },
  {
    href: 'http://oszczesciu.wordpress.com',
    thumbnail: thumb05,
    caption: 'My master thesis on happiness (in an infographic)',
    description:
      'Analysis of the influence of social network on perceived happiness',
  },
  {
    href: 'http://sawka.pro/generator',
    thumbnail: thumb06,
    caption: 'Speech generator (Polish)',
    description: 'A fun tool to generate lengthy speeches',
  },
]

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <section id="one">
          <SEO title="All posts" />
          <header className="major">
            <h2>Hello, world!</h2>
          </header>
          <p>I am a web developer with background in data analysis.</p>
          <p>
            Combining my programming and analytical skills is what I enjoy most.
            Having worked for a few years as a financial analyst I realized I
            needed more creativity at work, and that turned me into programming.
            Apart from programming I enjoy calisthenics, wandering around
            strange places and art.
            <br />
          </p>
          {/*<ul className="actions">*/}
          {/*<li><a href="#" className="button">Learn More</a></li>*/}
          {/*</ul>*/}
        </section>
        <section id="blog">
          <h2>Writing</h2>
          <ul>
            <li>
              <a href="/why_i_became_a_programmer">Why I became a programmer</a>
            </li>
            <li>
              <a href="/how_i_became_a_programmer">...and how I did it :)</a>
            </li>
          </ul>
        </section>
        <section id="two">
          <h2>Projects</h2>
          {/*<p>Or <span className='cursive'>How I learned to program</span></p>*/}
          <Projects
            images={DEFAULT_IMAGES.map(
              ({ href, thumbnail, caption, description }) => ({
                href,
                thumbnail,
                caption,
                description,
              })
            )}
          />

          {/*<ul className="actions">*/}
          {/*<li><a href="#" className="button">Full Portfolio</a></li>*/}
          {/*</ul>*/}
        </section>

        {/*<Bio />*/}
        {/*{posts.map(({ node }) => {*/}
        {/*  const title = node.frontmatter.title || node.fields.slug*/}
        {/*  return (*/}
        {/*    <article key={node.fields.slug}>*/}
        {/*      <header>*/}
        {/*        <h3*/}
        {/*          style={{*/}
        {/*            marginBottom: rhythm(1 / 4),*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <Link style={{ boxShadow: `none` }} to={node.fields.slug}>*/}
        {/*            {title}*/}
        {/*          </Link>*/}
        {/*        </h3>*/}
        {/*        <small>{node.frontmatter.date}</small>*/}
        {/*      </header>*/}
        {/*      <section>*/}
        {/*        <p*/}
        {/*          dangerouslySetInnerHTML={{*/}
        {/*            __html: node.frontmatter.description || node.excerpt,*/}
        {/*          }}*/}
        {/*        />*/}
        {/*      </section>*/}
        {/*    </article>*/}
        {/*  )*/}
        {/*})}*/}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    sky: imageSharp(id: { regex: "/bg2.jpg/" }) {
      sizes(
        quality: 100
        traceSVG: { color: "rgb(56, 47, 92)", threshold: 75 }
        toFormat: JPG
      ) {
        tracedSVG
        src
      }
    }
    #
    #        allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
    #            edges {
    #                node {
    #                    excerpt
    #                    fields {
    #                        slug
    #                    }
    #                    frontmatter {
    #                        date(formatString: "MMMM DD, YYYY")
    #                        title
    #                        description
    #                    }
    #                }
    #            }
    #        }
  }
`
