import React from 'react'
import PropTypes from 'prop-types'
import '../assets/scss/main.scss'
import Header from '../components/Header'

class Template extends React.Component {
  render() {
    const { children, data } = this.props

    return (
      <div>
        <Header {...data} />
        <div id="main">
        {children}
        </div>
      </div>
    )
  }
}

Template.propTypes = {
  children: PropTypes.func
}

export default Template
