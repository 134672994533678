import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.png'



const Header = (props) => {
  return (
    <header id="header">
      <div className="inner">
        <a href="#" className="image avatar"><img src={avatar} alt=""/></a>
        <h1><strong>mateusz sawka</strong><br/></h1>
        <h3>web dev / data viz</h3>
      </div>
      <Footer/>
    </header>
  )
}

export default Header
